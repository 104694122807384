var site = site || {};

site.translations = site.translations || {};
site.translations.elc_general = site.translations.elc_general || {};

(function ($, site) {
  Drupal.behaviors.threeColumnFormatterV1 = {
    attached: false,
    attach: function (context) {
      var self = this;
      var $formatter = $('.js-three-column-grid-v1', context);

      if (self.attached) {
        return;
      }
      self.attached = true;

      $formatter.each(function () {
        var $thisFormatter = $(this);

        var $carousel = $thisFormatter.find('.js-carousel');
        var disableCarousel = $carousel.hasClass('js-disable-carousel');
        var carouselDataset = $carousel.data() || null;
        var slickSlide = '.js-carousel__slide';
        var $arrowsDiv = $thisFormatter.find('.js-carousel-controls');
        var $dotsDiv = $thisFormatter.find('.js-carousel-dots');
        var autoplay = carouselDataset.slidesAutoplay || false;
        var slidesToShow = carouselDataset.slidesToShow || 3;
        var slidesToScroll = carouselDataset.slidesToScroll || 1;
        var speed = carouselDataset.slidesSpeed || 300;
        var fade = carouselDataset.slidesFade ? true : false;
        var pcDotsArrowsArgs = self.checkDotsAndArrows(carouselDataset.dotsArrowsPc);
        var mobileDotsArrowsArgs = self.checkDotsAndArrows(carouselDataset.dotsArrowsMobile);
        var settingsPlain = {
          rtl: site.direction.isRTL,
          appendArrows: $arrowsDiv,
          slide: slickSlide,
          fade: fade,
          speed: speed,
          slidesToShow: slidesToShow,
          slidesToScroll: slidesToScroll,
          dots: pcDotsArrowsArgs.dots,
          arrows: pcDotsArrowsArgs.arrows,
          cssEase: 'cubic-bezier(.66, 0, .33, 1)'
        };
        var settingsResponsive = {
          rtl: site.direction.isRTL,
          appendArrows: $arrowsDiv,
          slide: slickSlide,
          fade: fade,
          speed: speed,
          slidesToShow: slidesToShow,
          slidesToScroll: slidesToScroll,
          autoplay: autoplay,
          infinite: false,
          cssEase: 'cubic-bezier(.66, 0, .33, 1)',
          dots: pcDotsArrowsArgs.dots,
          arrows: pcDotsArrowsArgs.arrows,
          appendDots: $dotsDiv,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                dots: mobileDotsArrowsArgs.dots,
                arrows: mobileDotsArrowsArgs.arrows,
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        };
        var useResponsive = slidesToShow > 3 ? true : false;
        if (disableCarousel) {
          return;
        }
        if (useResponsive === true) {
          $carousel.not('.slick-initialized').slick(settingsPlain);
        } else {
          $carousel.not('.slick-initialized').slick(settingsResponsive);
        }
      });
    },
    checkDotsAndArrows: function (dotsAndArrows) {
      var dotsArrowsArgs = {};

      switch (dotsAndArrows) {
        case 'arrows':
          dotsArrowsArgs.dots = false;
          dotsArrowsArgs.arrows = true;
          break;
        case 'dots':
          dotsArrowsArgs.dots = true;
          dotsArrowsArgs.arrows = false;
          break;
        case 'both':
          dotsArrowsArgs.dots = true;
          dotsArrowsArgs.arrows = true;
          break;
        case 'none':
          dotsArrowsArgs.dots = false;
          dotsArrowsArgs.arrows = false;
          break;
      }

      return dotsArrowsArgs;
    }
  };
})(jQuery, window.site || {});
